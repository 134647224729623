import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const Grengel: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.Grengel,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <path
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Grengel"
          d="M1012.6,876.2c5.7,6,11.8,13.5,19.2,17.5c6.7,2.3,14.6,5.5,16.8,12.9c4.4,4.2,11.1,2.1,16.1,0.2 c2.3,0.1,5,1.1,6.5,2.9c1.8,2.8,6.2,11,7.1,14.3c0.2,3.8-4.6,9.4-6.5,12.5c-36.8,24.2-74.4,47.8-107.4,77.2L948,994l12-36l-16-42 h-16l-2-8l-20,2v14l-4,2c-2.8-16.7-9.2-54.9-12-72l24,6l52-28l46,44l0.2-0.2L1012.6,876.2z"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 970.8724 920.3549)"
        >
          Grengel
        </text>
      </g>
    </>
  );
});
